<template>
  <div class="none-borders">
    <div class="top_data">
      <div>
        <span class="data_title">发票：</span>
        未确认<span class="data_count">{{ info?.noSureInvoiceTotal }}</span>
        已确认<span class="data_count">{{ info?.sureInvoiceTotal }}</span>
      </div>
      <div>
        <span class="data_title">银行：</span>
        未确认<span class="data_count">{{ info?.noSureSalaryTotal }}</span>
        已确认<span class="data_count">{{ info?.sureBankTotal }}</span>
      </div>
      <div>
        <span class="data_title">薪酬：</span>
        未确认<span class="data_count">{{ info?.noSureSalaryTotal }}</span
        >已确认<span class="data_count">{{ info?.sureSalaryTotal }}</span>
      </div>
    </div>
    <div class="main_content">
      <div class="top_select">
        <div
          class="each"
          v-for="(item, index) in options"
          :key="index"
          @click="selectType(item)"
          :class="{ active: item.value == listQuery.method }"
        >
          <div class="icon_bg">
            <i :class="item.icon"></i>
          </div>
          {{ item.label }}
        </div>
      </div>
      <div class="mb-10 flex_between">
        <div class="filter_style">
          <el-button
            icon="RefreshRight"
            size="small"
            @click="getList()"
            class="mr-5"
          ></el-button>
          <el-input
            size="small"
            placeholder="请输入公司名称"
            v-model.trim="listQuery.name"
            class="w-160"
            @keyup.enter="getList"
            clearable
          ></el-input>
          <el-button
            size="small"
            type="primary"
            @click="getList"
            class="mr-5"
            icon="Search"
            >搜索</el-button
          >
          <search @success="getList" @cancel="cancel">
            <el-form
              style="margin: 11px 0px 0px 15px; width: 470px"
              label-width="130px"
              size="small"
            >
              <el-form-item label="税局 ：">
                <addressList
                  v-model:type="listQuery.address"
                  class="w-140"
                ></addressList>
              </el-form-item>
              <el-form-item label="公司类型：">
                <el-select
                  v-model="listQuery.companyType"
                  placeholder="请选择公司类型"
                  filterable
                  clearable
                  class="w-140"
                >
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :label="
                  listQuery.method == 'invoice'
                    ? '发票确认状态：'
                    : listQuery.method == 'bank'
                    ? '银行确认状态：'
                    : '薪酬确认状态：'
                "
              >
                <select-common
                  placeholder="请选择确认状态"
                  v-model:modelValue="listQuery.ackStatus"
                  :options="confirmOption"
                  class="w-140"
                />
              </el-form-item>
              <div v-if="listQuery.method == 'invoice'">
                <el-form-item label="进项采集任务结果：">
                  <selectBussinessCjStatus
                    v-model:allStatus="listQuery.inAllStatus"
                  ></selectBussinessCjStatus>
                </el-form-item>
                <el-form-item label="销项采集任务结果：">
                  <selectBussinessCjStatus
                    v-model:allStatus="listQuery.outAllStatus"
                    :type="false"
                  ></selectBussinessCjStatus>
                </el-form-item>
              </div>
              <el-form-item
                label="银行采集状态："
                v-if="listQuery.method == 'bank'"
              >
                <selectCjJzStatus
                  v-model:allStatus="listQuery.bankAllStatus"
                ></selectCjJzStatus>
              </el-form-item>
              <el-form-item
                label="取数状态："
                v-if="listQuery.method == 'salary'"
              >
                <el-radio-group v-model="listQuery.gsMainStatus">
                  <el-radio :label="0">全部</el-radio>
                  <el-radio :label="1">已取数</el-radio>
                  <el-radio :label="2">未取数</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </search>
          <el-radio-group
            v-model="listQuery.isNot0"
            style="margin-left: 5px"
            size="small"
            @change="getList"
          >
            <el-radio-button :label="0">全部</el-radio-button>
            <el-radio-button :label="1"
              >有{{
                listQuery.method == "invoice"
                  ? "单据"
                  : listQuery.method == "bank"
                  ? "银行"
                  : "薪酬"
              }}</el-radio-button
            >
            <el-radio-button :label="2"
              >无{{
                listQuery.method == "invoice"
                  ? "单据"
                  : listQuery.method == "bank"
                  ? "银行"
                  : "薪酬"
              }}</el-radio-button
            >
          </el-radio-group>
        </div>

        <div>
          <!-- <el-button
            type="primary"
            size="small"
            @click="copyBill"
            :disabled="!$buttonStatus('sktj_fzsk')"
            icon="DocumentCopy"
            >复制单据
          </el-button> -->
          <el-button
            @click="confirmAll()"
            type="primary"
            size="small"
            plain
            icon="Checked"
            >确认
          </el-button>
        </div>
      </div>
      <el-table
        stripe
        :data="list"
        :height="contentStyleObj"
        border
        @selection-change="handleSelectionChange"
        v-loading="loading"
        @sort-change="sortChange"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column
          label="编号"
          align="center"
          width="80"
          fixed="left"
          prop="sort"
          sortable="custom"
        >
          <template #default="scope">
            <TableSortCell :row="scope.row" idKey="comId" />
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          prop="name"
          label="公司名称"
          min-width="240"
          fixed="left"
        >
          <template #default="scope">
            <TagNameCopy
              :row="scope.row"
              idKey="comId"
              :showAuthIcon="false"
            ></TagNameCopy>
          </template>
        </el-table-column>
        <el-table-column align="center" label="税局" width="75">
          <template #default="scope">
            <span>{{ $cityFilter(scope.row.district) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="inAmount1"
          label="进项发票金额"
          min-width="140"
          v-if="listQuery.method == 'invoice'"
        >
          <template #default="scope">
            <div>
              <div v-if="scope.row.type == 2">
                <div v-if="scope.row.inAmount1 * 1">
                  金额：{{ parseFloat(scope.row.inAmount1) }}
                </div>
                <div v-else>金额：0.00</div>
                <div
                  v-if="
                    (scope.row.inAmount3 * 100 - scope.row.inAmount1 * 100) /
                      100 !=
                    0
                  "
                >
                  税额：{{
                    (
                      (scope.row.inAmount3 * 100 - scope.row.inAmount1 * 100) /
                      100
                    ).toFixed2()
                  }}
                </div>
                <div v-else>税额：0.00</div>
              </div>
              <div v-if="scope.row.type == 1">
                <div v-if="scope.row.inAmount3 * 1">
                  价税合计：{{ Number(scope.row.inAmount3).toFixed2() }}
                </div>
                <div v-else>价税合计：0.00</div>
                <div
                  v-if="
                    (scope.row.inAmount3 * 100 - scope.row.inAmount1 * 100) /
                      100 !=
                    0
                  "
                >
                  税额：{{
                    (
                      (scope.row.inAmount3 * 100 - scope.row.inAmount1 * 100) /
                      100
                    ).toFixed2()
                  }}
                </div>
                <div v-else>税额：0.00</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="districtName"
          label="进项采集任务状态"
          width="230"
          v-if="listQuery.method == 'invoice'"
        >
          <template #default="scope">
            <div class="div_p">
              <div class="item_icon">
                <p>
                  <el-tooltip
                    :disabled="!scope.row.inErrLog && !scope.row.inBusinessLog"
                    effect="dark"
                    placement="top-start"
                  >
                    <template #content>
                      <div
                        v-html="
                          scope.row.inErrLog
                            ? scope.row.inErrLog
                            : '' + scope.row.inBusinessLog
                            ? scope.row.inBusinessLog
                            : ''
                        "
                      ></div>
                    </template>
                    <div>
                      <div
                        style="display: inline-block"
                        v-if="
                          scope.row.inImg &&
                          scope.row.inTaskStatus == 3 &&
                          scope.row.inBusinessStatus != 2
                        "
                      >
                        <i
                          class="iconfont icon-picture"
                          style="color: #67c23a; font-size: 16px"
                          v-if="scope.row.inBusinessStatus == 5"
                          @click="open(scope.$index, 'inImg')"
                        ></i>
                        <i
                          class="iconfont icon-picture"
                          style="color: red; font-size: 16px"
                          v-else
                          @click="open(scope.$index, 'inImg')"
                        ></i>
                      </div>
                      <i
                        v-else
                        :class="
                          $batchSendIconCj(
                            scope.row.inTaskStatus,
                            scope.row.inBusinessStatus
                          )
                        "
                      ></i>
                      <span style="padding-left: 0">{{
                        $batchSendCj(
                          scope.row.inTaskStatus,
                          scope.row.inBusinessStatus
                        )
                      }}</span>
                      <span
                        v-if="
                          scope.row.inAmount2 != '' &&
                          scope.row.inBusinessStatus == 2
                        "
                        style="margin-left: 5px"
                        >{{ scope.row.inAmount2 }}张</span
                      >
                      <i
                        class="iconfont icon-wenhao"
                        v-if="
                          (scope.row.inErrLog &&
                            scope.row.inErrLog != '成功') ||
                          (scope.row.inBusinessLog &&
                            scope.row.inBusinessLog != '成功')
                        "
                        style="font-size: 15px; color: red; margin-left: 4px"
                      ></i>
                    </div>
                  </el-tooltip>
                </p>
                <span>{{ scope.row.inEndAt }}</span>
              </div>
              <p style="padding-top: 12px">
                <el-button
                  @click="declareDetail(scope.row, 'in')"
                  size="small"
                  link
                  >采集记录</el-button
                >
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="outAmount1"
          label="销项发票金额"
          min-width="140"
          v-if="listQuery.method == 'invoice'"
        >
          <template #default="scope">
            <div>
              <div v-if="scope.row.type == 2">
                <div v-if="scope.row.outAmount1 * 1">
                  金额：{{ parseFloat(scope.row.outAmount1) }}
                </div>
                <div v-else>金额：0.00</div>
                <div
                  v-if="
                    (scope.row.outAmount3 * 100 - scope.row.outAmount1 * 100) /
                      100 !=
                    0
                  "
                >
                  税额：{{
                    (
                      (scope.row.outAmount3 * 100 -
                        scope.row.outAmount1 * 100) /
                      100
                    ).toFixed2()
                  }}
                </div>
                <div v-else>税额：0.00</div>
              </div>
              <div v-if="scope.row.type == 1">
                <div v-if="scope.row.outAmount3 * 1">
                  价税合计：{{ Number(scope.row.outAmount3).toFixed2() }}
                </div>
                <div v-else>价税合计：0.00</div>
                <div
                  v-if="
                    (scope.row.outAmount3 * 100 - scope.row.outAmount1 * 100) /
                      100 !=
                    0
                  "
                >
                  税额：{{
                    (
                      (scope.row.outAmount3 * 100 -
                        scope.row.outAmount1 * 100) /
                      100
                    ).toFixed2()
                  }}
                </div>
                <div v-else>税额：0.00</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="districtName"
          label="销项采集任务状态"
          width="230"
          v-if="listQuery.method == 'invoice'"
        >
          <template #default="scope">
            <div class="div_p">
              <div class="item_icon">
                <p>
                  <el-tooltip
                    :disabled="
                      (scope.row.outErrLog
                        ? scope.row.outErrLog
                        : '' + scope.row.outBusinessLog
                        ? scope.row.outBusinessLog
                        : '') == ''
                        ? true
                        : false
                    "
                    class="item"
                    effect="dark"
                    placement="top-start"
                  >
                    <template #content>
                      <div
                        v-html="
                          scope.row.outErrLog
                            ? scope.row.outErrLog
                            : '' + scope.row.outBusinessLog
                            ? scope.row.outBusinessLog
                            : ''
                        "
                      ></div>
                    </template>
                    <div>
                      <div
                        style="display: inline-block"
                        v-if="
                          scope.row.outImg &&
                          scope.row.outTaskStatus == 3 &&
                          scope.row.outBusinessStatus != 2
                        "
                      >
                        <i
                          class="iconfont icon-picture"
                          style="color: #67c23a; font-size: 16px"
                          v-if="scope.row.outBusinessStatus == 5"
                          @click="open(scope.$index, 'outImg')"
                        ></i>
                        <i
                          class="iconfont icon-picture"
                          style="color: red; font-size: 16px"
                          v-else
                          @click="open(scope.$index, 'outImg')"
                        ></i>
                      </div>
                      <i
                        v-else
                        :class="
                          $batchSendIconCj(
                            scope.row.outTaskStatus,
                            scope.row.outBusinessStatus
                          )
                        "
                      ></i>
                      <span style="padding-left: 0">
                        {{
                          $batchSendCj(
                            scope.row.outTaskStatus,
                            scope.row.outBusinessStatus
                          )
                        }}</span
                      >
                      <span
                        v-if="
                          scope.row.outAmount2 != '' &&
                          scope.row.outBusinessStatus == 2
                        "
                        style="margin-left: 5px"
                        >{{ scope.row.outAmount2 }}张</span
                      >
                      <i
                        class="iconfont icon-wenhao"
                        v-if="
                          (scope.row.outErrLog &&
                            scope.row.outErrLog != '成功') ||
                          (scope.row.outBusinessLog &&
                            scope.row.outBusinessLog != '成功')
                        "
                        style="font-size: 15px; color: red; margin-left: 4px"
                      ></i>
                    </div>
                  </el-tooltip>
                </p>
                <span>{{ scope.row.outEndAt }}</span>
              </div>
              <p style="padding-top: 12px">
                <el-button
                  @click="declareDetail(scope.row, 'out')"
                  size="small"
                  link
                  >采集记录</el-button
                >
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          prop="count"
          label="银行"
          min-width="120"
          v-if="listQuery.method == 'bank'"
        >
          <template #default="scope">
            <div
              class="div_p"
              v-for="item in scope.row.bankItems"
              :key="item.bankId"
            >
              <div class="item_icon">
                {{ item.tableNameBank }}
              </div>
              <p v-if="item.businessStatusBank == 2">
                {{ item.countBank ? item.countBank : 0 }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          prop="count"
          label="采集状态"
          min-width="120"
          v-if="listQuery.method == 'bank'"
        >
          <template #default="scope">
            <div
              class="div_p"
              v-for="(item, index) in scope.row.bankItems"
              :key="item.bankId"
            >
              <div class="item_icon">
                <p>
                  <el-tooltip
                    :disabled="!item.errLogBank && !item.businessLogBank"
                    effect="dark"
                    placement="top-start"
                  >
                    <template #content>
                      <div
                        v-html="
                          item.errLogBank
                            ? item.errLogBank
                            : '' + item.businessLogBank
                            ? item.businessLogBank
                            : ''
                        "
                      ></div>
                    </template>
                    <div>
                      <div
                        style="display: inline-block"
                        v-if="
                          item.image &&
                          item.taskStatusBank == 3 &&
                          item.businessStatusBank != 2
                        "
                      >
                        <i
                          class="iconfont icon-picture"
                          style="color: red; font-size: 16px"
                        ></i>
                      </div>
                      <i
                        v-if="!item.image"
                        :class="
                          $batchSendIconCj(
                            item.taskStatusBank,
                            item.businessStatusBank
                          )
                        "
                      ></i>
                      <span style="padding-left: 0">{{
                        $batchSendCj(
                          item.taskStatusBank,
                          item.businessStatusBank
                        )
                      }}</span>
                      <i
                        class="iconfont icon-wenhao"
                        v-if="
                          (item.errLogBank && item.errLogBank != '成功') ||
                          (item.businessLogBank &&
                            item.businessLogBank != '成功')
                        "
                        style="font-size: 15px; color: red; margin-left: 4px"
                      ></i>
                    </div>
                  </el-tooltip>
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          prop="count"
          label="银行收款金额"
          min-width="80"
          v-if="listQuery.method == 'bank'"
        >
          <template #default="scope">
            <div
              class="div_p"
              v-for="(item, index) in scope.row.bankItems"
              :key="index"
            >
              <p>
                <span v-if="item.businessStatusBank == 2">
                  {{ item.inAmountBank ? item.inAmountBank : 0 }}
                </span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          prop="count"
          label="银行支出金额"
          min-width="80"
          v-if="listQuery.method == 'bank'"
        >
          <template #default="scope">
            <div
              class="div_p"
              v-for="(item, index) in scope.row.bankItems"
              :key="index"
            >
              <p>
                <span v-if="item.businessStatusBank == 2">
                  {{ item.outAmountBank ? item.outAmountBank : 0 }}
                </span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          prop="districtName"
          label="申报税种"
          min-width="230"
          :filters="categories"
          v-if="listQuery.method == 'salary'"
        >
          <template #default="scope">
            <div
              class="div_p"
              v-for="item in scope.row.items"
              :key="item.gsMainId"
            >
              <p style="text-align: left">
                {{ $tableNameFilter(item.tableName) }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          label="取数状态"
          min-width="100"
          v-if="listQuery.method == 'salary'"
        >
          <template #default="scope">
            <div
              class="div_p"
              v-for="item in scope.row.items"
              :key="item.gsMainId"
            >
              <p>{{ item.gsMainStatus == "1" ? "已取数" : "未取数" }}</p>
              <el-button
                size="small"
                link
                :disabled="
                  item.gsMainStatus != '1' || !$buttonStatus('zdjz_xcck')
                "
                @click="checkTableDialog(scope.row, item)"
                v-if="item.tableName == 'gs_gs'"
                >查看</el-button
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="薪资总额"
          width="80"
          prop="amount"
          v-if="listQuery.method == 'salary'"
        >
        </el-table-column>
        <el-table-column
          align="left"
          prop="districtName"
          label="系统应补（退）税额"
          min-width="155"
          v-if="listQuery.method == 'salary'"
        >
          <template #default="scope">
            <div
              class="div_p"
              v-for="item in scope.row.items"
              :key="item.gsMainId"
            >
              {{ item.tax ? item.tax : 0 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="随手记"
          min-width="120"
          show-overflow-tooltip
          v-if="['invoice','bank'].includes(listQuery.method)"
        >
          <template #default="scope">
            <span
              class="xgmStyle"
              style="cursor: pointer"
              @click="smallNotes(scope.row)"
              >记</span
            >
            <span>{{ scope.row.jotDown }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="随手记"
          min-width="120"
          show-overflow-tooltip
          v-else
        >
          <template #default="scope">
            <span
              class="xgmStyle"
              style="cursor: pointer"
              @click="smallNotes(scope.row)"
              >记</span
            >
            <span>{{ scope.row.content }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="发票确认状态"
          width="100"
          v-if="listQuery.method == 'invoice'"
          fixed="right"
        >
          <template #default="scope">
            <el-tooltip
              :disabled="scope.row.djqr_invoice_status != 2"
              effect="dark"
              placement="top-start"
              :content="scope.row.djqr_invoice_err"
            >
              <div class="item_icon">
                <i :class="$homeConfirmIcon(scope.row.djqr_invoice_status)"></i>
                <span>{{
                  $homeConfirmStatus(scope.row.djqr_invoice_status)
                }}</span>
                <i
                  class="iconfont icon-wenhao"
                  v-if="scope.row.djqr_invoice_status == 2"
                  style="font-size: 15px; color: red; margin-left: 4px"
                ></i>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          label="发票确认人"
          width="100"
          v-if="listQuery.method == 'invoice'"
          fixed="right"
        >
          <template #default="scope">
            <div v-if="[1, 3].includes(scope.row.djqr_invoice_status)">
              {{
                scope.row.djqr_invoice_status == 1
                  ? scope.row.djqr_invoice_name
                  : "系统自动"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="银行确认状态"
          width="100"
          v-if="listQuery.method == 'bank'"
          fixed="right"
        >
          <template #default="scope">
            <el-tooltip
              :disabled="scope.row.djqr_bank_status != 2"
              effect="dark"
              placement="top-start"
              :content="scope.row.djqr_bank_err"
            >
              <div class="item_icon">
                <i :class="$homeConfirmIcon(scope.row.djqr_bank_status)"></i>
                <span>{{
                  $homeConfirmStatus(scope.row.djqr_bank_status)
                }}</span>
                <i
                  class="iconfont icon-wenhao"
                  v-if="scope.row.djqr_bank_status == 2"
                  style="font-size: 15px; color: red; margin-left: 4px"
                ></i>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          label="银行确认人"
          width="100"
          v-if="listQuery.method == 'bank'"
          fixed="right"
        >
          <template #default="scope">
            <div v-if="[1, 3].includes(scope.row.djqr_bank_status)">
              {{
                scope.row.djqr_bank_status == 1
                  ? scope.row.djqr_bank_name
                  : "系统自动"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="薪酬确认状态"
          width="100"
          v-if="listQuery.method == 'salary'"
          fixed="right"
        >
          <template #default="scope">
            <el-tooltip
              :disabled="scope.row.djqr_salary_status != 2"
              effect="dark"
              placement="top-start"
              :content="scope.row.djqr_salary_err"
            >
              <div class="item_icon">
                <i :class="$homeConfirmIcon(scope.row.djqr_salary_status)"></i>
                <span>{{
                  $homeConfirmStatus(scope.row.djqr_salary_status)
                }}</span>
                <i
                  class="iconfont icon-wenhao"
                  v-if="scope.row.djqr_salary_status == 2"
                  style="font-size: 15px; color: red; margin-left: 4px"
                ></i>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          label="薪酬确认人"
          width="100"
          v-if="listQuery.method == 'salary'"
          fixed="right"
        >
          <template #default="scope">
            <div v-if="[1, 3].includes(scope.row.djqr_salary_status)">
              {{
                scope.row.djqr_salary_status == 1
                  ? scope.row.djqr_salary_name
                  : "系统自动"
              }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <qzf-pagination
          v-show="total > 0"
          :total="total"
          v-model:page="listQuery.page"
          v-model:limit="listQuery.limit"
          @pagination="getList"
        />
      </div>
    </div>
    <!-- 采集记录 -->
    <declareDetails ref="declareDetailsRef"></declareDetails>
    <sbImgUrl ref="sbImgUrlRef"></sbImgUrl>
    <jotDown ref="jotDownRef" @jotDownUpdate="updateNote" />
  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, watch } from "vue";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import {
  cjCompanyList,
  companySalaryList,
  ackCompany,
  sureTotal,
} from "@/api/home";
import addressList from "@/components/Screening/address";
import selectCjJzStatus from "../../company/components/selectCjJzStatus.vue";
import declareDetails from "../../batch/components/declareDetails.vue";
import sbImgUrl from "../../batch/components/sbImgUrl.vue";
import jotDown from "@/components/jotDown";
import {
  COMPANY_TYPE_OPTION,
  HOME_CONFIRM_STATUS,
} from "@/utils/commonData.js";
import selectBussinessCjStatus from "../../batch/components/select/selectBussinessCjStatus.vue";
const { proxy } = getCurrentInstance();
const props = defineProps({
  period: { type: String, default: "" },
  changeHeight: { type: Boolean, default: false },
  taxType: { type: String, default: "" },
  userId: { type: Array, default: () => [] },
});
const options = [
  { label: "发票确认", value: "invoice", icon: "iconfont icon-fapiao" },
  { label: "银行确认", value: "bank", icon: "iconfont icon-xinchou1" },
  { label: "薪酬确认", value: "salary", icon: "iconfont icon-xinchou" },
];
const listQuery = ref({
  name: "",
  page: 1,
  limit: 20,
  inAllStatus: [],
  outAllStatus: [],
  accountingType: 0,
  address: "",
  jzStatus: 0,
  companyType: "",
  method: "invoice",
  period: "",
  taskName: "gs-sb-",
  from: "个税",
  automatic: 2,
  isNot0: 0,
});
const list = ref([]);
const total = ref(0);
const contentStyleObj = ref({});
const loading = ref(false);
const userName = proxy.$store.getters["user/user"].cnName;
const typeOptions = COMPANY_TYPE_OPTION;
const confirmOption = HOME_CONFIRM_STATUS.filter((v) => v.value != 4);
const declareDetailsRef = ref();
const sbImgUrlRef = ref();
const info = ref({});
const sels = ref([]);
const jotDownRef = ref();
watch(
  () => [props.period, props.taxType, props.userId],
  (newVal) => {
    listQuery.value.period = newVal[0];
    listQuery.value.type = newVal[1];
    listQuery.value.userId = newVal[2];
    getList();
    getInfo();
  },
  { immediate: true }
);
watch(
  () => props.changeHeight,
  (newVal) => {
    if (newVal) {
      contentStyleObj.value = proxy.$getHeight(540);
    } else {
      contentStyleObj.value = proxy.$getHeight(410);
    }
  }
);
onMounted(() => {
  contentStyleObj.value = props.changeHeight
    ? proxy.$getHeight(540)
    : proxy.$getHeight(410);
});
const selectType = (item) => {
  listQuery.value.method = item.value;
  getList();
};
function getList() {
  loading.value = true;
  if (listQuery.value.method != "salary") {
    cjCompanyList(listQuery.value).then((res) => {
      loading.value = false;
      if (res.data.msg == "success") {
        list.value = res.data.data.list;
        total.value = res.data.data.total;
      }
    });
  } else {
    companySalaryList(listQuery.value).then((res) => {
      loading.value = false;
      if (res.data.msg == "success") {
        list.value = res.data.data.list;
        total.value = res.data.data.total;
      }
    });
  }
}
function getInfo() {
  let param = {
    page: 1,
    limit: 99999,
    period: props.period,
    type: props.taxType,
    userId: props.userId,
  };
  sureTotal(param).then((res) => {
    if (res.data.msg == "success") {
      info.value = res.data.data;
    }
  });
}
const handleSelectionChange = (e) => {
  sels.value = e;
};
//批量确认
const confirmAll = () => {
  if (sels.value.length == 0) {
    proxy.$qzfMessage("请选择公司", 1);
    return;
  }
  let fail = statusValid();
  if (fail) {
    proxy.$qzfMessage(`${fail.name}不可确认，请重新选择`, 1);
    return;
  }
  const maps = {
    invoice: "djqr_invoice_status",
    bank: "djqr_bank_status",
    salary: "djqr_salsry_status",
  };
  let confirmFail = sels.value.find((v) =>
    [1, 3].includes(v[maps[listQuery.value.method]])
  );
  if (confirmFail) {
    proxy.$qzfMessage(`${confirmFail.name}已确认，请重新选择`, 1);
    return;
  }
  proxy
    .$confirm("是否批量确认？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
    .then(() => {
      let param = {
        comIds: sels.value.map((v) => {
          return v.comId;
        }),
        period: listQuery.value.period,
        type: listQuery.value.method,
        status: 1,
        errLog: "",
        name: userName,
      };
      ackCompany(param).then((res) => {
        if (res.data.msg == "success") {
          proxy.$qzfMessage("确认成功");
          getList();
        }
      });
    });
};
//复制单据
const copyBill = () => {
  if (sels.value.length == 0) {
    proxy.$qzfMessage("请选择公司", 1);
    return;
  }
  let fail = statusValid();
  if (fail) {
    proxy.$qzfMessage(`${fail.name}不可复制单据，请重新选择`, 1);
    return;
  }
};
const statusValid = () => {
  let fail;
  if (listQuery.value.method == "invoice") {
    fail = sels.value.find(
      (v) => v.inBusinessStatus != 2 && v.outBusinessStatus != 2
    );
  } else if (listQuery.value.method == "bank") {
    sels.value.some((v) => {
      if (v.bankItems && v.bankItems.length > 0) {
        return v.bankItems.some((e) => {
          if (e.businessStatusBank !== "2") {
            fail = v;
            return true;
          }
          return false;
        });
      }
      return false;
    });
  } else {
    sels.value.some((v) => {
      if (v.items && v.items.length > 0) {
        return v.items.some((e) => {
          if (e.gsMainStatus !== 1) {
            fail = v;
            return true;
          }
          return false;
        });
      }
      return false;
    });
  }
  return fail;
};
// 采集记录
const declareDetail = (e, type) => {
  let a = type == "in" ? "tax-cj-inInvoice" : "tax-cj-outInvoice";
  declareDetailsRef.value.init(e.comId, listQuery.value.period, a);
};
const open = (index, type) => {
  let imageAll = [];
  list.value.map((res) => {
    let url = {
      name: res.name,
      url: "",
    };
    url.url = res[type]
      ? "https://file.listensoft.net" + res[type] + "?" + Math.random(10)
      : "";
    imageAll.push(url);
  });
  sbImgUrlRef.value.dialog(imageAll, index);
};
const smallNotes = (row) => {
  jotDownRef.value.init(row.name, row.comId, props.period);
};
const updateNote = (id, text) => {
  let key = listQuery.value.method == "salary" ? "content" : "jotDown";
  list.value.map((v) => {
    if (v.comId == id) {
      v[key] = text;
    }
  });
};
// 表格排序
const sortChange = (data) => {
  if (data.column.label == "编号") {
    if (data.order == "descending") {
      listQuery.value.desc = 1;
    } else {
      listQuery.value.desc = 0;
    }
    getList();
  }
};
//重置
const cancel = () => {
  let originLimit = listQuery.value.limit;
  let originMethod = listQuery.value.method;
  listQuery.value = {
    name: "",
    page: 1,
    limit: originLimit,
    inAllStatus: [],
    outAllStatus: [],
    accountingType: 0,
    address: "",
    jzStatus: 0,
    companyType: "",
    method: originMethod,
    period: props.period,
    taskName: "gs-sb-",
    from: "个税",
    automatic: 2,
    isNot0: 0,
    type: props.taxType,
    userId: props.userId,
  };
  getList();
};
</script>

<style scoped lang="scss">
.top_data {
  width: 100%;
  background-color: #edf8fa;
  padding: 10px;
  display: flex;
  align-items: center;
  .data_count {
    color: #17a2b8;
    margin: 0 5px;
  }
  & > div {
    width: 33%;
    border-right: 1px solid #dbddde;
    text-align: center;
  }
  div:last-child {
    border-right: none;
  }
  .data_title {
    margin-right: 20px;
    position: relative;
  }
  .data_title::before {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    left: -11px;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--themeColor, #17a2b8);
  }
}
.top_select {
  display: flex;
  align-items: center;
  margin: 10px 0;
  .each {
    width: 200px;
    padding: 5px 15px;
    text-align: center;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
    .icon_bg {
      width: 27px;
      height: 27px;
      border-radius: 50%;
      background-color: #cae7ef;
      text-align: center;
      line-height: 27px;
      display: inline-block;
      margin-right: 7px;
    }
    .iconfont {
      color: #17a2b8;
    }
  }
  .active {
    background-color: #edf8fa;
    border-color: #a3d9e1;
  }
}

.filter_style {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.none-borders .div_p {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child {
    border-bottom: none;
  }
}
.item_icon {
  cursor: pointer;
  i {
    display: inline-block;
    font-size: 13px;
    margin-right: 4px;
  }
  p {
    font-size: 13px;
    color: #333;
    text-align: left;
  }
}
</style>
