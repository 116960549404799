<template>
  <div style="width:100%;height:100%;padding:10px">
    <div style="width:100%;height:20%">
      <div style="margin-bottom:10px;display: flex;align-items: center;justify-content: space-between;">
        <div >
          <span class="leftBlueBorder"></span>
          <span style="font-size:14px;font-weight:500;margin-right:40px;margin-left:10px">进度统计</span>
          <span style="font-size:13px;color:#333">你好{{cnName}},今天是我们相识的第<span style="font-size:16px;color:var(--themeColor,#17a2b8)">{{apartTime}}</span>天,本月报税期截止时间为<span style="font-size:16px;color:var(--themeColor,#17a2b8)">{{deadlineDay}}</span>号,<span v-if="today<=deadlineDay&&showStatus">距离报税期结束还有<span style="font-size:16px;color:var(--themeColor,#17a2b8)">{{deadlineDay-today}}</span>天,</span>继续加油！</span>
        </div>
        <div style="z-index:2">
          
          <span style="font-size: 14px;">账期选择：</span><qzf-search-period v-model:period="listQuery.period" @success="changePeriod" size="small" style="margin-left:5px;"></qzf-search-period>
          <selecttaxtype v-model:type="listQuery.type" @success="getList()"></selecttaxtype>
          <selectuser code="bookkeeping" v-model:userId="listQuery.userId" @success="getList()" style="margin: 0;width:130px"></selectuser>
        </div>
      </div>
      <div style="width:100%;display:flex;height:100%;align-items: center;justify-content: space-between;" >
        <div class="leftExample" style="background-color: #e7f5fe;border-radius: 10px;box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;">
          <bookTotal ref="bookTotal" @refresh-bookTotal="bookTotalWks"></bookTotal>
        </div>
        <div class="leftExample" style="background-color: #e8feef;border-radius: 10px;box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;">
          <shenBaoTotal ref="shenBaoTotal" @refresh-shenBaoTotal="shenBaoTotalWks"></shenBaoTotal>
        </div>
        <div class="leftExample" style="background-color: #e9f5fe;border-radius: 10px;box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;">
          <chargebackTotal ref="chargebackTotal" @refresh-chargebackTotal="chargebackTotalWks"></chargebackTotal>
        </div>
        <div class="leftExample" style="background-color: #eeeeff;border-radius: 10px;box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;">
          <checkTotal ref="checkTotal" @refresh-checkTotal="checkTotalWks"></checkTotal>
        </div>
      </div>
    </div>
    <!-- <div>
      <div style="width:100%;display:inline-block;position:relative;margin-top: 10px;" v-if="this.isAdmin == 1">
        <span class="leftBlueBorder"></span>
        <span style="font-size:15px;font-weight:500;margin-right:40px;margin-left:10px">客户类型统计</span>
        <span style="font-size:14px;margin-right:10px">当前可使用账套数 <span style="color:var(--themeColor,#17a2b8)">{{ this.count - this.xgmCount-this.ybCount }}</span> 户</span>
        <span style="font-size:14px;margin-right:10px">总账套数<span style="color:var(--themeColor,#17a2b8)"> {{ this.count }}</span>  户</span>
        <span style="font-size:14px;margin-right:10px;">记账客户总数<span style="color:var(--themeColor,#17a2b8)"> {{ this.xgmCount+this.ybCount }}</span>  户</span>
        <span style="font-size:14px;margin-right:10px">一般纳税人<span style="color:#ff7a00">{{ this.ybCount }}</span> 户 占比<span style="color:#ff7a00">{{ isNaN(((this.ybCount/(this.xgmCount+this.ybCount))*100).toFixed(2))?0:((this.ybCount/(this.xgmCount+this.ybCount))*100).toFixed(2) }}%</span></span>   
        <span style="font-size:14px;margin-right:10px">小规模纳税人<span style="color:var(--themeColor,#17a2b8)">{{ this.xgmCount }}</span> 户    占比<span style="color:var(--themeColor,#17a2b8)">{{ isNaN(((this.xgmCount/(this.xgmCount+this.ybCount))*100).toFixed(2))?0: ((this.xgmCount/(this.xgmCount+this.ybCount))*100).toFixed(2)}}%</span></span>
        <span style="font-size:14px;">到期时间: <span style="color:var(--themeColor,#17a2b8)">{{ this.endTime }}</span> </span>
      </div>
    </div> -->
    <div style="margin-top:12px">
      <div class="top_select">
        <div>
          <el-button icon="RefreshRight" size="small" style="margin-right: 5px;" @click="getList()"></el-button>
          <el-input size="small" style="width:287px" v-model="listQuery.name" @keyup.enter="getList" placeholder="请输入企业名称/编号"></el-input>
          <el-button size="small" type="primary" @click="getList" style="margin-right:10px">
            <el-icon><Search /></el-icon><span  > 搜索</span>
          </el-button>
          <search @success="getList" @cancel="cancel" name="状态筛选">
            <el-form style="margin:11px 0px 0px 15px;width:460px" class="styleForm">
              <el-form-item label="登录状态：" :label-width="formLabelWidth">   
                <selectAccreditStatus v-model:type="listQuery.keepStatus" style="width:140px"></selectAccreditStatus>
              </el-form-item>
              <el-form-item label="到票状态：">
                <el-radio v-model="listQuery.piaoStatus" :label="9" size="small" >未到票</el-radio>
                <el-radio v-model="listQuery.piaoStatus" :label="1" size="small" >部分到票</el-radio>
                <el-radio v-model="listQuery.piaoStatus" :label="2" size="small" >全部到票</el-radio>
              </el-form-item>
              <el-form-item label="记账状态：">   
                <el-radio v-model="listQuery.inputStatus" :label="9" size="small" >未开始</el-radio>
                <el-radio v-model="listQuery.inputStatus" :label="1" size="small" >进行中</el-radio>
                <el-radio v-model="listQuery.inputStatus" :label="2" size="small" >已完成</el-radio>
              </el-form-item>
              <el-form-item label="申报状态：">   
                <el-radio v-model="listQuery.declaredStatus" :label="9" size="small" >未申报</el-radio>
                <el-radio v-model="listQuery.declaredStatus" :label="1" size="small" >进行中</el-radio>
                <el-radio v-model="listQuery.declaredStatus" :label="2" size="small" >已完成</el-radio>
                <el-radio v-model="listQuery.declaredStatus" :label="3" size="small" >无需申报</el-radio>
              </el-form-item>
              <el-form-item label="扣款状态：">   
                <el-radio v-model="listQuery.deductionStatus" :label="9" size="small" >未扣款</el-radio>
                <el-radio v-model="listQuery.deductionStatus" :label="1" size="small" >进行中</el-radio>
                <el-radio v-model="listQuery.deductionStatus" :label="2" size="small" >已完成</el-radio>
                <el-radio v-model="listQuery.deductionStatus" :label="3" size="small" >无需扣款</el-radio>
              </el-form-item>
              <el-form-item label="检查状态：">   
                <el-radio v-model="listQuery.checkStatus" :label="9" size="small" >未检查</el-radio>
                <el-radio v-model="listQuery.checkStatus" :label="1" size="small" >进行中</el-radio>
                <el-radio v-model="listQuery.checkStatus" :label="2" size="small" >已完成</el-radio>
              </el-form-item>
            </el-form>
          </search>
        </div>
        <div>
          <qzf-video vid="63dd60f588e5da5f6906de2a3cd2d704_6" ></qzf-video>
          <el-button size="small" type="primary" plain icon="Edit" @click="batchEditStatus">修改到票状态</el-button>
          <el-button size="small" type="success" plain icon="Folder" :loading="daochuLoading" @click="daochu" :disabled="!$buttonStatus('sy_dc')">导出</el-button>
        </div>
      </div>
      <el-table :data="list" :height="contentStyleObj" border @sort-change="sortChange" v-loading="loading" stripe @select="handleSelectionChange" ref="tableScroll" id="tableLazyLoad" @select-all="handleSelectionChangeAll">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" align="center" width="55" />
        <el-table-column sortable="custom" label="编号"  align="center" prop="sort" width="80" fixed="left">
          <template #default="scope">
            <TableSortCell :row="scope.row" />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="公司名称" min-width="300" fixed="left">
          <template #default="scope">
            <TagNameCopy :row="scope.row"></TagNameCopy>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="到票状态" width="130" >
          <template #default="scope">
            <div class="item_icon" v-if="scope.row.piaoStatus == 9">
              <i class="iconfont icon-gantanhao"></i>
              <p>未到票</p>
            </div>
            <div class="item_icon" v-else-if="scope.row.piaoStatus == 1">
              <i class="iconfont icon-info"></i>
              <p>部分到票</p>
            </div>
            <div class="item_icon" v-if="scope.row.piaoStatus == 2">
              <i class="iconfont icon-duihao"></i>
              <p>全部到票</p>
            </div>
            <i class="iconfont icon-bianji" @click="ticketOpen(scope.row)" style="cursor:pointer" v-if="$buttonStatus('sy_dpzt')"></i>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="记账状态" width="100" >
          <template #default="scope">
            <div class="item_icon" v-if="scope.row.inputStatus == 9">
              <i class="iconfont icon-gantanhao"></i>
              <p>未记账</p>
            </div>
            <div class="item_icon" v-else-if="scope.row.inputStatus == 1">
              <i class="iconfont icon-info"></i>
              <p>进行中</p>
            </div>
            <div class="item_icon" v-if="scope.row.inputStatus == 2">
              <i class="iconfont icon-duihao"></i>
              <p>已完成</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="申报状态" width="100" >
          <template #default="scope">
            <div class="item_icon" v-if="scope.row.declaredStatus == 9">
              <i class="iconfont icon-gantanhao"></i>
              <p>未申报</p>
            </div>
            <div class="item_icon" v-else-if="scope.row.declaredStatus == 1">
              <i class="iconfont icon-info"></i>
              <p>进行中</p>
            </div>
            <div class="item_icon" v-if="scope.row.declaredStatus == 2">
              <i class="iconfont icon-duihao"></i>
              <p>已完成</p>
            </div>
            <div class="item_icon" v-if="scope.row.declaredStatus == 3">
              <i class="iconfont icon-duihao"></i>
              <p>无需申报</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="扣款状态" width="100" >
          <template #default="scope">
            <div class="item_icon" v-if="scope.row.deductionStatus == 9">
              <i class="iconfont icon-gantanhao"></i>
              <p>未扣款</p>
            </div>
            <div class="item_icon" v-else-if="scope.row.deductionStatus == 1">
              <i class="iconfont icon-info"></i>
              <p>进行中</p>
            </div>
            <div class="item_icon" v-if="scope.row.deductionStatus == 2">
              <i class="iconfont icon-duihao"></i>
              <p>已完成</p>
            </div>
            <div class="item_icon" v-if="scope.row.deductionStatus == 3">
              <i class="iconfont icon-duihao"></i>
              <p>无需扣款</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="检查状态" width="100" >
          <template #default="scope">
            <div class="item_icon" v-if="scope.row.checkStatus == 9">
              <i class="iconfont icon-gantanhao"></i>
              <p>未检查</p>
            </div>
            <div class="item_icon" v-else-if="scope.row.checkStatus == 1">
              <i class="iconfont icon-info"></i>
              <p>进行中</p>
            </div>
            <div class="item_icon" v-if="scope.row.checkStatus == 2">
              <i class="iconfont icon-duihao"></i>
              <p>已完成</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="随手记" min-width="150" v-if="$buttonStatus('jzkh_ssj')" show-overflow-tooltip>
          <template #default="scope">
            <span class="xgmStyle" style="cursor:pointer;" @click="smallNotes(scope.row)">记</span>
            <span>{{scope.row.jotDown}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="220" align="center">
          <template #default="scope">
            <el-button @click="$goEtax(scope.row.id)" size="small" type="primary" plain :disabled="!$buttonStatus('jzkh_jrzb')">
              <el-icon><House /></el-icon><span  > 进账簿</span>
            </el-button>
            <taxBureau :comId="scope.row.id" @success="getList" />
            <!-- <el-button size="small" plain type="warning" @click="InTax(scope.row)" :loading="scope.row.taxLoading" :disabled="!$buttonStatus('jzkh_jrsj')">
              <el-icon><HomeFilled /></el-icon><span  > 进税局</span>
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="homeIndex"/>
      </div>
    </div>
      <ticket-status ref="ticketStatus" @success="getList"></ticket-status>
      <!-- 税局小助手未安装提示 -->
  <loginTaxAlert ref="loginTaxAlert"></loginTaxAlert>
  <jotDown ref="jotDown" @jotDownUpdate="updateNote"/>
  </div>
</template>

<script>
import { currentAccountPeriod } from '@/utils'
import { indexEtax2 } from "@/api/home"
import axios from 'axios'
import {getIndexEtax2Print} from '@/api/export'
import {loginTaxInfo} from "@/api/company"
import selecttaxtype from "@/components/Screening/selecttaxtype";
import bookTotal from './components/bookTotal.vue'
import shenBaoTotal from './components/shenBaoTotal.vue'
import chargebackTotal from './components/chargebackTotal.vue'
import checkTotal from "./components/checkTotal"
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import jotDown from '@/components/jotDown'
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import taxBureau from "@/components/loginTaxAlert/taxBureau.vue"
export default {
  // name:'homeIndex',
  components:{ bookTotal , shenBaoTotal ,chargebackTotal , checkTotal,selecttaxtype,selectAccreditStatus,jotDown,TagNameCopy,TableSortCell,taxBureau },

  data(){
     return {
      list:[],
      listQuery:{
        period:currentAccountPeriod(),
        name:'',
        limit:20,
        page:1,
      },
      total:0,
      cnName:this.$store.getters['user/user'].cnName,
      deadlineDay:'',
      apartTime:'',
      count:0,
      xgmCount:0,
      ybCount:0,
      noteContent:'',
      isAdmin:this.$store.getters['user/user'].isAdmin,
      dialogConfirm:false,
      fullscreenLoading:false,
      endTime:'',
      loading:false,
      daochuLoading:false,
      sels:[],
      totalPage:0,
      pageSize:35,
      tableData:[],
      currentPage:1,
      allChecked:false,
      today:new Date().getDate(),
      showStatus:false,
      orgId: this.$store.getters["user/user"].orgId,
    }
  },
  created(){
    this.listQuery.limit = localStorage.getItem('homeIndex') ? localStorage.getItem('homeIndex')*1 : 20
    this.getList()
    this.contentStyleObj= this.$getHeight(370)
  },
  mounted(){
  },
  methods:{
    // 重置
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery={
        period:currentAccountPeriod(),
        name:'',
        limit:originLimit,
        page:1,
        keepStatus:null,
        piaoStatus:null,
        inputStatus:null,
        declaredStatus:null,
        deductionStatus:null,
        checkStatus:null
      }
      this.getList()
    },
    // 记账统计
    bookTotalWks(type){
      this.loading = true
      this.listQuery.declaredStatus = null
      this.listQuery.deductionStatus = null
      this.listQuery.checkStatus = null
      if(type=='wks'){
        this.listQuery.inputStatus = 9
      }else if(type=='jxz'){
        this.listQuery.inputStatus = 1
      }else{
        this.listQuery.inputStatus = 2
      }
      this.init()
    },
    // 申报
    shenBaoTotalWks(type){
      this.loading = true
      this.listQuery.inputStatus = null
      this.listQuery.deductionStatus = null
      this.listQuery.checkStatus = null
      if(type=='wks'){
        this.listQuery.declaredStatus = 9
      }else if(type=='jxz'){
        this.listQuery.declaredStatus = 1
      }else{
        this.listQuery.declaredStatus = 2
      }
      this.init()
    },
    // 扣款统计
    chargebackTotalWks(type){
      this.loading = true
      this.listQuery.inputStatus = null
      this.listQuery.declaredStatus = null
      this.listQuery.checkStatus = null
      if(type=='wks'){
        this.listQuery.deductionStatus = 9
      }else if(type=='jxz'){
        this.listQuery.deductionStatus = 1
      }else{
        this.listQuery.deductionStatus = 2
      }
      this.init()
    },
    // 检查统计
    checkTotalWks(type){
      this.loading = true
      this.listQuery.inputStatus = null
      this.listQuery.declaredStatus = null
      this.listQuery.deductionStatus = null
      if(type=='wks'){
        this.listQuery.checkStatus = 9
      }else if(type=='jxz'){
        this.listQuery.checkStatus = 1
      }else{
        this.listQuery.checkStatus = 2
      }
      this.init()
    },
    init(){
      indexEtax2(this.listQuery).then(res=>{
        this.loading = false
        this.tableData = res.data.data.list ? res.data.data.list : []
        this.total = res.data.data.total
        this.apartTime = res.data.data.apartTime
        this.deadlineDay = res.data.data.day
        this.setScroll()
      })
    },
     // 表格排序
     sortChange(data) {
      this.listQuery.orderBy = data.order == "descending" ? "c.sort_com desc,c.id desc" : "c.sort_com,c.id desc"
      this.getList()
    },
    getList(){
      this.loading = true
      this.showStatus = this.listQuery.period != currentAccountPeriod() ? false : true
      indexEtax2(this.listQuery).then(res=>{
        this.loading = false
        this.tableData = res.data.data.list ? res.data.data.list : []
        this.total = res.data.data.total
        this.apartTime = res.data.data.apartTime
        this.deadlineDay = res.data.data.day
        this.$refs.bookTotal.init(res.data.data.jzzt)
        this.$refs.shenBaoTotal.init(res.data.data.sbzt)
        this.$refs.chargebackTotal.init(res.data.data.kkzt)
        this.$refs.checkTotal.init(res.data.data.jczt)
        this.count = res.data.data.count
        this.xgmCount = res.data.data.xgmCount
        this.ybCount = res.data.data.ybCount
        let endTime = res.data.data.endTime.replace('-','年')
        this.endTime = endTime.replace('-','月').concat('日')
        this.setScroll()
      })
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.sels = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.sels.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.sels.length != 0){
              that.sels.map(v=>{
                that.list.map(item=>{
                  if(item.id == v.id){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    smallNotes(row){
      this.$refs.jotDown.init(row.name,row.id,this.listQuery.period)
    },
    ticketOpen(row){
      let params = {
        comIds:[row.id],
        period:this.listQuery.period
      }
      this.$refs.ticketStatus.openDialog()
      this.$refs.ticketStatus.ticketStatus(params)
    },
    batchEditStatus(){
      if(this.sels.length == 0){
        this.$qzfMessage('请选择公司',1)
        return
      }
      let ids = []
      this.sels.map(v=>{
        ids.push(v.id)
      })
      let param = {
        comIds:ids,
        period:this.listQuery.period
      }
      this.$refs.ticketStatus.openDialog()
      this.$refs.ticketStatus.ticketStatus(param)
    },
    InTax(row){
      // if(this.orgId == 3576){
      //   this.$qzfMessage('此功能暂时无法使用',1)
      //   return
      // }
      row.taxLoading = true
      let that = this
      loginTaxInfo({id:row.id}).then(res=>{
        let param = res.data.data.info
        if(param.isLock){
          this.$confirm('任务端机器人正在执行报税任务，如要强制进入税局，账号将会被顶掉，导致任务中断，您确认进入税局吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
          }).then(() => {
            this.$qzfMessage('打开中，预计15秒...');
            axios.post('http://localhost:9111/loginTax/autoLoginTax',param).then(res=>{
              row.taxLoading = false
              if(res.data.msg != "success"){
                this.getList()
                that.$alert(res.data.msg, '提示', {
                  confirmButtonText: '确定',
                });
              } 
            }).catch(function () {
              row.taxLoading = false
              setTimeout(() => {
                that.$refs.loginTaxAlert.dialogVisible = true
              }, 1000);
            });
          }).catch(()=>{
            row.taxLoading = false
          });
         }else{
          this.$qzfMessage('打开中，预计15秒...');
          axios.post('http://localhost:9111/loginTax/autoLoginTax',param).then(res=>{
            row.taxLoading = false
            if(res.data.msg != "success"){
              this.getList()
              that.$alert(res.data.msg, '提示', {
                confirmButtonText: '确定',
              });
            } 
          }).catch(function () {
            row.taxLoading = false
            setTimeout(() => {
              that.$refs.loginTaxAlert.dialogVisible = true
            }, 1000);
            return
          });
        }
      })
    },
    changePeriod(){
      // 切换账期时候筛选清空
      this.listQuery = {
        period:this.listQuery.period,
        name:'',
        limit:20,
        page:1,
      }
      this.getList()
    },
    handleSelectionChange(val){
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.id == v.id){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.sels = newArr
      }else{
        this.sels = val;
      }
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.sels = this.tableData
        this.allChecked = true
      }else{
        this.sels = []
        this.allChecked = false
      }
    },
    daochu(){
      this.daochuLoading = true
      let ids = []
      this.sels.map(v=>{
        ids.push(v.id)
      })
      let param = {
        query:this.listQuery,
        comIds:ids
      }
      getIndexEtax2Print(param).then(res=>{
        this.daochuLoading = false
        window.open(res.data.data.path)
      })
    },
    //更新随手记之后的回调
    updateNote(id,text){
      this.list.map(v=>{
        if(v.id == id){
          v.jotDown = text
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
:deep(textarea){
  min-height: 145px !important;
}
.leftExample{
  width:24%;
  display:inline-block;
  height: 80%;
  position: relative;
}
.item_icon{
  display: inline-block;
  width: 81%;
  i{
    display: inline-block;
    font-size: 13px;
    margin-right: 4px;
  }
  p{
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}
.leftBlueBorder{
  width:4px;
  height: 12px;
  background-color: var(--themeColor,#17a2b8);
  display: inline-block;
}
.top_select{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.styleForm{
  .el-form-item{
    margin-bottom: 8px;
  }
}
</style>