<template>
  <div class="content_top">
    <div>
      <span style="font-size: 13px; color: #333"
        >你好{{ cnName }},今天是我们相识的第<span class="time_color">{{
          apartTime
        }}</span
        >天,本月报税期截止时间为<span class="time_color">{{ deadlineDay }}</span
        >号,<span v-if="today <= deadlineDay && showText"
          >距离报税期结束还有<span class="time_color">{{
            deadlineDay - today
          }}</span
          >天,</span
        >继续加油！</span
      >
    </div>
    <div>
      <span>账期选择：</span
      ><qzf-search-period
        v-model:period="period"
        @success="changePeriod"
        size="small"
      ></qzf-search-period>
      <selecttaxtype
        v-model:type="taxType"
        @success="changeType"
      ></selecttaxtype>
      <selectuser
        code="bookkeeping"
        v-model:userId="userId"
        @success="changeUser"
        class="w-130 ml-mr-0"
      ></selectuser>
    </div>
  </div>
  <div class="echart_top">
    <div class="record">进度统计（已使用账套数：{{ xgmCount + ybCount }}）</div>
    <div class="switch_icon" v-if="showStatus" @click="switchShow">
      <span>收起进度统计</span
      ><el-icon class="rotate--90"><d-arrow-right /></el-icon>
    </div>
    <div class="switch_icon" v-else @click="switchShow">
      <span>展开进度统计</span
      ><el-icon class="rotate--90"><d-arrow-left /></el-icon>
    </div>
  </div>
  <div v-if="showStatus" class="echart_style">
    <div class="leftExample" style="background-color: #e8feef">
      <danjuTotal ref="danjuTotalRef"></danjuTotal>
    </div>
    <div class="leftExample" style="background-color: #e7f5fe">
      <bookTotal ref="bookTotalRef"></bookTotal>
    </div>
    <div class="leftExample" style="background-color: #eeeeff">
      <queshuiTotal ref="queshuiTotalRef"></queshuiTotal>
    </div>
    <div class="leftExample" style="background-color: #e8feef">
      <shenBaoTotal ref="shenBaoTotalRef"></shenBaoTotal>
    </div>
    <div class="leftExample" style="background-color: #e9f5fe">
      <chargebackTotal ref="chargebackTotalRef"></chargebackTotal>
    </div>
    <div class="leftExample" style="background-color: #eeeeff">
      <checkTotal ref="checkTotalRef"></checkTotal>
    </div>
  </div>
  <div class="tab-container">
    <div class="tabs">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        :class="['tab', { active: activeTab === index }]"
        @click="selectTab(index)"
      >
        <i :class="tab.icon"></i> {{ tab.name }}
        <div v-if="activeTab === index" class="triangle"></div>
      </div>
    </div>
    <div>
      <component
        :is="currentTabComponent"
        :period="period"
        :fromType="from"
        :changeHeight="showStatus"
        :taxType="taxType"
        :userId="userId"
      ></component>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, getCurrentInstance, nextTick } from "vue";
import { currentAccountPeriod } from "@/utils";
import { indexEtax3 } from "@/api/home";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import documenStatus from "./components/documentStatus.vue";
import taxation from "./components/taxation.vue";
import bookDeclare from "./components/bookDeclare.vue";
import danjuTotal from "./components/danjuTotal.vue"; //单据统计
import bookTotal from "./components/bookTotal.vue"; //记账统计
import queshuiTotal from "./components/queshuiTotal.vue"; //确税统计
import shenBaoTotal from "./components/shenBaoTotal.vue"; //申报统计
import chargebackTotal from "./components/chargebackTotal.vue"; //扣款统计
import checkTotal from "./components/checkTotal.vue"; //检查统计
const { proxy } = getCurrentInstance();
const tabs = [
  {
    name: "单据状态",
    icon: "iconfont icon-zhangbu1",
  },
  {
    name: "记账状态",
    icon: "iconfont icon-sheshuixinxichaxun",
  },
  {
    name: "税款确认",
    icon: "iconfont icon-baobiaotongji",
  },

  {
    name: "报税状态",
    icon: "iconfont icon-shuiwubaobiao1",
  },
];
const cnName = proxy.$store.getters["user/user"].cnName;
const activeTab = ref(0);
const period = ref(currentAccountPeriod());
const taxType = ref("");
const userId = ref([]);
const from = ref("");
const showStatus = ref(false);
const count = ref(0);
const deadlineDay = ref("");
const apartTime = ref("");
const today = ref(new Date().getDate());
const showText = ref(false);
const danjuTotalRef = ref();
const bookTotalRef = ref();
const queshuiTotalRef = ref();
const shenBaoTotalRef = ref();
const chargebackTotalRef = ref();
const checkTotalRef = ref();
const jzzt = ref({});
const sbzt = ref({});
const kkzt = ref({});
const jczt = ref({});
const djtj = ref({});
const qstj = ref({});
const xgmCount = ref(0);
const ybCount = ref(0);
onMounted(() => {
  getList();
});
const getList = () => {
  showText.value = period.value != currentAccountPeriod() ? false : true;
  indexEtax3({
    period: period.value,
    type: taxType.value,
    userId: userId.value,
  }).then((res) => {
    if (res.data.msg == "success") {
      count.value = res.data.data.count;
      xgmCount.value = res.data.data.xgmCount;
      ybCount.value = res.data.data.ybCount;
      apartTime.value = res.data.data.apartTime;
      deadlineDay.value = res.data.data.day;
      jzzt.value = res.data.data.jzzt;
      sbzt.value = res.data.data.sbzt;
      kkzt.value = res.data.data.kkzt;
      jczt.value = res.data.data.jczt;
      djtj.value = res.data.data.djtj;
      qstj.value = res.data.data.qstj;
      if (showStatus.value) showEcharts();
    }
  });
};
const selectTab = (index) => {
  activeTab.value = index;
  if (index == 1 || index == 3) {
    from.value = index == 1 ? "book" : "tax";
  }
};
const changePeriod = (newVal) => {
  period.value = newVal;
  getList();
};
const changeType = (newVal) => {
  taxType.value = newVal;
  getList();
};
const changeUser = (newVal) => {
  userId.value = newVal;
  getList();
};
const switchShow = () => {
  showStatus.value = !showStatus.value;
  if (showStatus.value) showEcharts();
};
const showEcharts = () => {
  nextTick(() => {
    bookTotalRef.value?.init(jzzt.value);
    shenBaoTotalRef.value?.init(sbzt.value);
    chargebackTotalRef.value?.init(kkzt.value);
    checkTotalRef.value?.init(jczt.value);
    danjuTotalRef.value?.init(djtj.value);
    queshuiTotalRef.value?.init(qstj.value);
  });
};
const currentTabComponent = computed(() => {
  switch (activeTab.value) {
    case 0:
      return documenStatus;
    case 1:
      return bookDeclare;
    case 2:
      return taxation;
    default:
      return bookDeclare;
  }
});
</script>

<style scoped lang="scss">
.content_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid #e3e3e3;
}
.tab-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
}
.tabs {
  display: flex;
  border-bottom: 1px solid var(--themeColor, #17a2b8);
}
.tab {
  width: 25%;
  text-align: center;
  padding: 8px 0px;
  cursor: pointer;
  position: relative;
}

.tab.active {
  color: var(--themeColor, #17a2b8);
}

.tab .triangle {
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 9px;
  height: 9px;
  border: none;
  background: #f3fbfe;
  border-top: 1px solid var(--themeColor, #17a2b8);
  border-right: 1px solid var(--themeColor, #17a2b8);
  transform: rotate(-45deg) translateX(2px) translateY(1px);
  z-index: 2;
}

.echart_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 0;
}
.switch_icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  span {
    font-size: 12px;
    margin-right: 2px;
  }
}
.rotate--90 {
  transform: rotate(-90deg);
}
.record {
  font-size: 14px;
  color: #333;
}
.record::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 7px;
}
.echart_style {
  padding: 5px 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leftExample {
  width: 16%;
  display: inline-block;
  height: 80%;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.time_color {
  font-size: 16px;
  color: var(--themeColor, #17a2b8);
}
</style>
