<template>
  <homeIndex2 v-if="orgId == 3576"/>
  <homeIndex v-else/>
</template>

<script setup>
import { getCurrentInstance } from "vue";
import homeIndex from "./homeIndex.vue";
import homeIndex2 from "./homeIndex2.vue";
const { proxy } = getCurrentInstance();
const orgId = proxy.$store.getters["user/user"].orgId;
</script>
<script>
export default {
  name: "homeEntrance",
};
</script>
