<template>
  <div class="totalStyle">确税统计</div>
  <div
    id="queshuiTotal"
    v-if="show"
    class="home_echarts"
    style="width: 35%; height: 100%"
  ></div>
  <div v-else class="home_echarts"></div>
  <div class="home_content">
    <div class="home_content_text">
      <ul>
        <li>未开始</li>
        <li>进行中</li>
        <li>已完成</li>
      </ul>
    </div>
    <div class="home_progress">
      <div class="home_progress_each">
        <el-progress
          :text-inside="true"
          :stroke-width="12"
          :percentage="wksPer"
          style="width: 70%"
          status="warning"
          color="#afacac"
        ></el-progress
        ><span style="font-size: 11px">{{ list.wks }}家</span>
      </div>
      <div class="home_progress_each">
        <el-progress
          :text-inside="true"
          :stroke-width="12"
          :percentage="jxzPer"
          style="width: 70%"
          status="info"
          color="#409eff"
        ></el-progress
        ><span style="font-size: 11px">{{ list.jxz }}家</span>
      </div>
      <div class="home_progress_each">
        <el-progress
          :text-inside="true"
          :stroke-width="12"
          :percentage="ywcPer"
          style="width: 70%"
          status="success"
          color="#67c23a"
        ></el-progress
        ><span style="font-size: 11px">{{ list.ywc }}家</span>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import * as echarts from "echarts";
export default {
  name: "queshuiTotal",
  props: {},
  data() {
    return {
      show: false,
      wksPer: 0,
      jxzPer: 0,
      ywcPer: 0,
      list: {},
      allTotal: 0,
    };
  },
  created() {},
  methods: {
    init(list) {
      this.$nextTick(() => {
        this.initChart(list);
      });
    },
    initChart(list) {
      this.show = false;
      let that = this;
      setTimeout(() => {
        that.show = true;
        that.$nextTick(() => {
          that.list = list;
          let wks = list?.wks;
          let jxz = list?.jxz;
          let ywc = list?.ywc;
          that.allTotal = wks + jxz + ywc;
          that.wksPer = wks == 0 ? 0 : ((wks / that.allTotal) * 100).toFixed(0);
          that.jxzPer = jxz == 0 ? 0 : ((jxz / that.allTotal) * 100).toFixed(0);
          that.ywcPer = ywc == 0 ? 0 : ((ywc / that.allTotal) * 100).toFixed(0);
          var myChart = echarts.init(document.getElementById("queshuiTotal"));
          let isGraphicVisible = true; // 控制 graphic 显示状态的变量
          myChart.setOption({
            title: {
              // text: '记账统计',
              left: "center",
              padding: 10,
              textStyle: {
                fontSize: "13",
                fontWeight: "normal",
              },
            },
            tooltip: {
              trigger: "none", //禁用悬浮提示
            },
            color: ["#afacac", "#409eff", "#67c23a"],
            series: [
              {
                type: "pie",
                radius: ["80%", "55%"],
                label: {
                  show: false,
                  position: "center",
                  formatter: "{b}:{c}" + "\n\r" + "({d}%)",
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: "12",
                  },
                },
                labelLine: {
                  show: true,
                },
                data: [
                  { value: wks, name: "未开始" },
                  { value: jxz, name: "进行中" },
                  { value: ywc, name: "已完成" },
                ],
              },
            ],
            graphic: {
              type: "text",
              left: "center",
              top: "center",
              style: {
                text: "总数:" + that.allTotal + "家", // 显示总数
                textAlign: "center",
                textVerticalAlign: "middle",
                fontSize: 9, // 根据需要调整字体大小
                fontColor: "#333",
                fontWeight: "500", // 根据需要调整字体样式
                opacity: isGraphicVisible ? 1 : 0, // 根据变量设置透明度
              },
            },
          });
          myChart.on("mouseover", { seriesType: "pie" }, () => {
            isGraphicVisible = false; // 鼠标移入时隐藏 graphic
            myChart.setOption({
              graphic: {
                style: {
                  opacity: 0, // 更新 graphic 的透明度
                },
              },
            });
          });

          myChart.on("mouseout", { seriesType: "pie" }, () => {
            isGraphicVisible = true; // 鼠标移出时显示 graphic
            myChart.setOption({
              graphic: {
                style: {
                  opacity: 1, // 更新 graphic 的透明度
                },
              },
            });
          });
          window.onresize = function () {
            myChart.resize();
          };
        });
      }, 500);
    },
  },
};
</script>
